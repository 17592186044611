import styled from 'styled-components';
import {device} from '../../../theme'

export const FormWrap = styled.form`
    position: relative;
    .form-output{
        margin-top: 10px;
        &.success{
            color: green;
        }
        &.errorMsg{
            color: red;
        }
    }
    .loading-modal-container{
        position:fixed;
        height:100%;
        width: 100%;
        background:rgba(0,0,0, .8);
        top:0;
        left:0;
        z-index: 99999999;

        .loading-modal{
            width:50%;
            height:auto;
            top:20%;
            left:25%;
            position: fixed;
            background:#fff;
            border-radius:8px;
            text-align: center;
            padding:40px;
            overflow:hidden;

            @media ${device.medium}{
              top:5%;
              left:5%;
              width:90%;
              padding:20px;
              overflow:hidden;
            }

            p{
              color:${props => props.theme.colors.headingColor};
              margin-bottom:20px;
            }

        }
    }
    .trip-form-container{
        border-bottom:${props => props.theme.colors.borderColor} 1px solid;
        max-width:1600px;
        width:90%;
        margin:0 auto;
        margin-bottom:20px;
        padding:40px 20px;
        @media ${device.medium}{
          padding:20px;
          width:100%;
          .one-way{
            margin-top:20px;
          }
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{ 
            font-weight: bold;
        }
        h5{
          margin-bottom:20px;
        }
        label{
            color:${props => props.theme.colors.headingColor};
            text-transform: capitalize;
            letter-spacing: .2px;
            font-weight:700;
        }

        .ant-picker-range, .ant-picker, .ant-input-number{
            height:50px;
            border:none;
            border-radius:0;
            border-bottom:1px solid ${props => props.theme.colors.themeColor};
            color:${props => props.theme.colors.themeColor};
            @media ${device.medium}{              
              width:100%;
            }
        }

        span.ant-checkbox{
            margin-top:-9px;
            float:left;
        }

        .ant-input-number-input{
            height:46px;
        } 
        button, .button{
            background:${props => props.theme.colors.themeColor};
            &:hover{
                box-shadow:none;
                color:#fff;
            }
            @media ${device.medium}{
              width:100%;
            }
        }
        .button{
            font-size: 15px;
            font-weight: 700;
            border-style: solid;
            border-color: transparent;
            padding: 20px 36px;
            text-align: center;
            border-width: 1px;
            -webkit-transform: translateY(0px);
            -ms-transform: translateY(0px);
            transform: translateY(0px);
            padding-left: 54px;
            padding-right: 54px;
            border-radius: 5px;
            color: #ffffff;
            cursor:pointer;
            line-height: 54px;
            height: 54px;
            box-sizing: border-box;
            width:auto;


            &:hover{
                transform: translateY(-3px);
            }
        }

    }
    .airport-select{
        width:400px;
        height:50px;
        background:#fff;
        border-bottom:1px solid ${props => props.theme.colors.themeColor};
        @media ${device.medium}{          
          width:100%;
        }
        
    }
    .airport-select .ant-select-selector {
        border:none !important;
        background:#fff;
        color:#000;
        margin-top:10px;
    }
    .airport-select input, .itinerary-quantity input {
        color:#000 !important;
    }    
`;

export const CheckoutDrawer = styled.div`
    
          z-index: 999999;
      h2{
          color:#000;
      }
      .ant-form-item-control-input-content{
          input{
              color:#000;
              border-radius:4px;
              &[type=text]{
                height:50px; 
              }
          }
      }
      .steps-action{
          position: absolute;
        bottom: 0;
        left: 0;
        background: #242943;
        height: 100px;
        width: 100%;

        button{
            height:50px;
            padding:10px 50px;
            margin-left:50px;
            margin-top:20px;
            &.button-next{
                float: right;
                margin-right:50px;
                font-weight: bold;
            }
        }
      }
      .steps-content{
          #nest-messages{
              background:rgba(50, 108, 252, .2);
              padding:20px;
              border-radius:10px;
              input{
                  background:#fff;
              }
          }
          h3{
              color:#000;
              margin-top:50px;
              margin-bottom:50px;
          }
          .step-one{
              label{
                  float: left;
                  margin-bottom:0;
                  margin-top:10px;
                  font-size:16px;
                  font-weight:500;
              }
          }          
          .purchase-form{
            background: #dee3fa;
            padding: 40px;
            margin-top: 50px;
            label{
                color:#000;
                float:left;
                width: 100%;
                font-size:16px;
            }
            .StripeElement{
                float: left;
                width: 100%;
                margin-top: 20px;
                height: 50px;
                padding-top: 13px;

            }
            button{
                background:#000;
            }
          }
      }

`


